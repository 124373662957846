
import {createStore, applyMiddleware} from 'redux';
import rootReducer from './rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'; //#remove in production
import thunk from 'redux-thunk'

const store = createStore(rootReducer, composeWithDevTools(
    //apply middleware here\
    applyMiddleware(
        thunk,
    )
))

export default store