import Table from 'react-tailwind-table'
import 'react-tailwind-table/dist/index.css';
import { FaEdit, FaTrash } from "react-icons/fa";
import React, { useState } from 'react';
import { deleteBranch, verifyPin } from '../../services';
import {history} from '../../helper/history';

function TillReport(props){
    
    const [deleteId, setDeleteId] = useState(0)
    const [pin, setPin] = useState(0)

    const [error, setError] = useState(false)

    const defaultStyling = {
        table_head:{
          table_row: "bg-red text-white",
          table_data:"text-black",
        },
    }

    function handleDelete(){
        //check pin 
        verifyPin(pin).then(res => {
            if(res.data.error){
                setError(true)
            }else{
                if(res.data.message.auth === true){
                    //fire delete element
                    deleteBranch(deleteId).then(res => {
                        if(res.data.message){
                            // redirect to location
                            setError(false)
                            history.push('/location')
                            window.location.reload()
                        }else{
                            setError(true)
                        }
                    })
                }else{
                    setError(true)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const rowCheck = (row,column, display_text) => {

        if (column.field === "action") {
          return (
              <div>
                <a className="btn btn-square btn-sm mr-4 tooltip tooltip-bottom hover:bg-main hover:border-neutral-content" data-tip="Edit" href={'/location/'+row.branch_id+'/edit'}>
                    <FaEdit className="ml-2 mt-2" />
                </a> 
                <a href="#delete" className="btn btn-square btn-sm tooltip tooltip-bottom hover:bg-main hover:border-neutral-content" data-tip="Delete" onClick={ () => setDeleteId(row.branch_id) } >
                    <FaTrash className="ml-2 mt-2" />
                </a>
              </div>
            );
        }
        return display_text
    }

    return(
        <div className="grid grid-flow-col mt-5">
            <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                <Table rows={props.row} columns={props.col} table_header={props.table_header} show_search={false} styling={defaultStyling} row_render={rowCheck} />
            </div>
            <div id="delete" className="modal">
                <div className="modal-box grid">
                    <div className="grid-flow-row">
                        <h1 className="text-center" >Are You sure you want to Delete?</h1> 
                    </div>
                    <div className="grid-flow-row mx-auto pt-5">
                        <input type="password" placeholder="Enter Pin" name="pin" className="input input-sm input-bordered justify-center" onChange={e => setPin(e.target.value)} />
                    </div>
                    <div className="grid-flow-row modal-action mx-auto">
                        <button className="btn btn-primary btn-sm" onClick={handleDelete}>Accept</button> 
                        <a href="/till#" className="btn btn-sm">Close</a>
                    </div>

                    {error=== true?
                        <div className="alert alert-error mt-3">
                            <div className="flex-1 justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 mx-2 stroke-current">    
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>                      
                                </svg> 
                                <label>Incorrect Pin, Please try again!</label>
                            </div>
                        </div>
                    :null}
                </div>
            </div>
        </div>
    )
}

export default TillReport;