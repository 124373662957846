import axios from 'axios';
import env from '../helper/env';
import {authHeader} from '../helper/authHeader';

export function getAllTills(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/tills',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader()
    })
}