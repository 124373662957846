import {userType} from '../types/userType'

const initialState = {
    loading: false,
    data: [],
    error: '',
    isAuthinticated: false,
}

const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case userType.LOGIN_REQUEST:
            return{
                ...state,
                loading: true,
                isAuthinticated: false,
            }
        case userType.LOGIN_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthinticated: true,
                data: action.payload, //from api
            }
        case userType.LOGIN_FAILURE:
            return{
                ...state,
                loading: false,
                isAuthinticated: false,
                error: action.payload, //from api
            }
            
        default: return state
    }
}

export default loginReducer;