import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from "../assets/images/logo/Logo.png";

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split('/')[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="lg:w-64">
      <div className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink exact to="/" className="block">
            <img src={Logo} alt="aloo-tama" />
          </NavLink>
        </div>

        {/* Links */}
        <div>
          <ul className="mt-3">
            {/* Dashboard */}
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'dashboard' && 'bg-gray-900'}`}>
              <NavLink exact to="/dashboard" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'dashboard' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Dashboard</span>
                </div>
              </NavLink>
            </li>
            {/* --------BRANCH--------- */}
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3 mt-5 mb-5">Branch</h3>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'location' && 'bg-gray-900'}`}>
              <NavLink exact to="/location" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'location' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Location</span>
                </div>
              </NavLink>
            </li>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'branch-user' && 'bg-gray-900'}`}>
              <NavLink exact to="/branch-user" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'branch-user' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">User</span>
                </div>
              </NavLink>
            </li>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'time' && 'bg-gray-900'}`}>
              <NavLink exact to="/time" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'time' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Opening Time</span>
                </div>
              </NavLink>
            </li>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'branch' && 'bg-gray-900'}`}>
              <NavLink exact to="/branch/setting" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'branch' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Others</span>
                </div>
              </NavLink>
            </li>
            {/* -----MENU-------- */}
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3 mt-5 mb-5">Menu</h3>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'vat' && 'bg-gray-900'}`}>
              <NavLink exact to="/vat" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'vat' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">VAT</span>
                </div>
              </NavLink>
            </li>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'tags' && 'bg-gray-900'}`}>
              <NavLink exact to="/tags" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'tags' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Tags</span>
                </div>
              </NavLink>
            </li>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'menu' && 'bg-gray-900'}`}>
              <NavLink exact to="/menu" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'menu' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Menu</span>
                </div>
              </NavLink>
            </li>

            {/* ------SALSE------- */}
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3 mt-5 mb-5">Report</h3>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'till' && 'bg-gray-900'}`}>
              <NavLink exact to="/till" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'till' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <span className="text-sm font-medium">Till</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;