import React, { Component } from 'react'
import { getBranchSetting, updateBranchSetting } from '../../../services'
import { errorToast, successToast } from '../../../components/Alerts';

export default class BranchSetting extends Component {

  state = {
    deliveryToggle: true,
    collectionToggle: true,
    cardToggle: true,
    cashToggle: true,
    minOrder: 0,
    collectionTime: 0,
    deliveryTime: 0,
  }

  componentDidMount(){
    getBranchSetting().then(res => {
      if(res.data.message !== null){
        this.setState({
          deliveryToggle: res.data.message.delivery,
          collectionToggle: res.data.message.collection,
          cardToggle: res.data.message.card,
          cashToggle: res.data.message.cash,
          minOrder: res.data.message.minimu_order,
          collectionTime: res.data.message.collection_time,
          deliveryTime: res.data.message.delivery_time,
        })
      }
    })
  }

  handleSave = () => {
    updateBranchSetting(this.state.collectionToggle, this.state.deliveryToggle, this.state.cashToggle, this.state.cardToggle, this.state.minOrder, this.state.collectionTime, this.state.deliveryTime).then(res => {
      successToast('Updated Sucessfully')
    }).catch(err => {
      errorToast('Error, Contact admin!')
    })
  }

  render() {
    return (
      <>
        <div className="grid grid-flow-col">
            <div className="flex justify-start">
                <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                    <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                    <li>/</li>
                    <li className="px-2">Others</li>
                </ol>
            </div>
        </div>
        <div className="grid grid-flow-col mt-5">
          <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
            <div className="flex justify-center items-center flex-col">
              <div className="form-control p-3 w-1/2">
                <span className="capitalize text-xl py-5 font-bold underline">Change Order Type</span>
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">Delivery</span> 
                  <input type="checkbox" checked={this.state.deliveryToggle?"checked":""} className="toggle toggle toggle-primary" onChange={() => (this.setState({deliveryToggle: !this.state.deliveryToggle}))} />
                </label>
              </div>
              <div className="form-control p-3 w-1/2">
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">Collection</span> 
                  <input type="checkbox" checked={this.state.collectionToggle?"checked":""} className="toggle toggle toggle-primary" onChange={() => (this.setState({collectionToggle: !this.state.collectionToggle}))} />
                </label>
              </div>
              <hr className="text-gray-500 w-full" />
              <div className="form-control p-3 w-1/2">
                <span className="capitalize text-xl py-5 font-bold underline">Payment Mode</span>
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">By Card</span> 
                  <input type="checkbox" checked={this.state.cardToggle?"checked":""} className="toggle toggle toggle-primary" onChange={() => (this.setState({cardToggle: !this.state.cardToggle}))} />
                </label>
              </div>
              <div className="form-control p-3 w-1/2">
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">Cash On Delivery</span> 
                  <input type="checkbox" checked={this.state.cashToggle?"checked":""} className="toggle toggle toggle-primary" onChange={() => (this.setState({cashToggle: !this.state.cashToggle}))} />
                </label>
              </div>
              <hr className="text-gray-500 w-full" />
              <div className="form-control p-3 w-1/2">
                <span className="capitalize text-xl py-5 font-bold underline">Minimum Order</span>
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">Amount</span> 
                  <input type="number" placeholder="Enter Minimum amount" value={this.state.minOrder} className="input input-bordered w-full max-w-xs" step={0.01} name="minOrder" onChange={(e) => (this.setState({minOrder: e.target.value})) } />
                </label>
              </div>
              <hr className="text-gray-500 w-full" />
              <div className="form-control p-3 w-1/2">
                <span className="capitalize text-xl py-5 font-bold underline">Collection/Delivery Time (minutes)</span>
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">Collection</span> 
                  <input type="number" placeholder="Enter Buffer time for collection" value={this.state.collectionTime} className="input input-bordered w-full max-w-xs" name="collectionTime" onChange={(e) => (this.setState({collectionTime: e.target.value})) } />
                </label>
                <label className="cursor-pointer label">
                  <span className="label-text hover:underline">Delivery</span> 
                  <input type="number" placeholder="Enter Buffer time for delivery" value={this.state.deliveryTime} className="input input-bordered w-full max-w-xs" name="deliveryTime" onChange={(e) => (this.setState({deliveryTime: e.target.value})) } />
                </label>
              </div>
              <div className="w-32">
                <button className="btn btn-primary btn-block" onClick={() => {this.handleSave()}}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
