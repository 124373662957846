import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getBranchTimeSlot, updateBranchTimeSlot } from '../../../services/branchService'
import { errorToast, successToast } from '../../../components/Alerts'

export default function OpeningTime() {

    const [startDateSunday, setStartDateSunday] = useState(new Date())
    const [endDateSunday, setEndDateSunday] = useState(new Date())
    const [statusSunday, setStatusSunday] = useState(true)
    
    const [startDateMonday, setStartDateMonday] = useState(new Date())
    const [endDateMonday, setEndDateMonday] = useState(new Date())
    const [statusMonday, setStatusMonday] = useState(true)

    const [startDateTuesday, setStartDateTuesday] = useState(new Date())
    const [endDateTuesday, setEndDateTuesday] = useState(new Date())
    const [statusTuesday, setStatusTuesday] = useState(true)

    const [startDateWednesday, setStartDateWednesday] = useState(new Date())
    const [endDateWednesday, setEndDateWednesday] = useState(new Date())
    const [statusWednesday, setStatusWednesday] = useState(true)
    
    const [startDateThursday, setStartDateThursday] = useState(new Date())
    const [endDateThursday, setEndDateThursday] = useState(new Date())
    const [statusThursday, setStatusThursday] = useState(true)

    const [startDateFriday, setStartDateFriday] = useState(new Date())
    const [endDateFriday, setEndDateFriday] = useState(new Date())
    const [statusFriday, setStatusFriday] = useState(true)
    
    const [startDateSaturday, setStartDateSaturday] = useState(new Date())
    const [endDateSaturday, setEndDateSaturday] = useState(new Date())
    const [statusSaturday, setStatusSaturday] = useState(true)
    
    const  [loading, setLoading] = useState(true)

    const data = [
        {
            'id': 1,
            'day': 'sunday',
            'from': startDateSunday,
            'to': endDateSunday,
            'status': statusSunday,
            'setStart': setStartDateSunday,
            'setEnd': setEndDateSunday,
            'setStatus': setStatusSunday
        },
        {
            'id': 2,
            'day': 'monday',
            'from': startDateMonday,
            'to': endDateMonday,
            'status': statusMonday,
            'setStart': setStartDateMonday,
            'setEnd': setEndDateMonday,
            'setStatus': setStatusMonday
        },
        {
            'id': 3,
            'day': 'tuesday',
            'from': startDateTuesday,
            'to': endDateTuesday,
            'status': statusTuesday,
            'setStart': setStartDateTuesday,
            'setEnd': setEndDateTuesday,
            'setStatus': setStatusTuesday
        },
        {
            'id': 4,
            'day': 'wednesday',
            'from': startDateWednesday,
            'to': endDateWednesday,
            'status': statusWednesday,
            'setStart': setStartDateWednesday,
            'setEnd': setEndDateWednesday,
            'setStatus': setStatusWednesday
        },
        {
            'id': 5,
            'day': 'thursday',
            'from': startDateThursday,
            'to': endDateThursday,
            'status': statusThursday,
            'setStart': setStartDateThursday,
            'setEnd': setEndDateThursday,
            'setStatus': setStatusThursday
        },
        {
            'id': 6,
            'day': 'friday',
            'from': startDateFriday,
            'to': endDateFriday,
            'status': statusFriday,
            'setStart': setStartDateFriday,
            'setEnd': setEndDateFriday,
            'setStatus': setStatusFriday
        },
        {
            'id': 7,
            'day': 'saturday',
            'from': startDateSaturday,
            'to': endDateSaturday,
            'status': statusSaturday,
            'setStart': setStartDateSaturday,
            'setEnd': setEndDateSaturday,
            'setStatus': setStatusSaturday
        }
    ]

    useEffect(()=>{
        // check if data comes from backend or not
        getBranchTimeSlot().then(res => {
            if(res.data.message !== null){
                JSON.parse(res.data.message.slots).forEach(element => {
                    if(element.day === 'sunday'){
                        setStartDateSunday(new Date(element.from))
                        setEndDateSunday(new Date(element.to))
                        setStatusSunday(element.status)
                    } else if(element.day === 'monday'){
                        setStartDateMonday(new Date(element.from))
                        setEndDateMonday(new Date(element.to))
                        setStatusMonday(element.status)
                    } else if(element.day === 'tuesday'){
                        setStartDateTuesday(new Date(element.from))
                        setEndDateTuesday(new Date(element.to))
                        setStatusTuesday(element.status)
                    } else if(element.day === 'wednesday'){
                        setStartDateWednesday(new Date(element.from))
                        setEndDateWednesday(new Date(element.to))
                        setStatusWednesday(element.status)
                    } else if(element.day === 'thursday'){
                        setStartDateThursday(new Date(element.from))
                        setEndDateThursday(new Date(element.to))
                        setStatusThursday(element.status)
                    } else if(element.day === 'friday'){
                        setStartDateFriday(new Date(element.from))
                        setEndDateFriday(new Date(element.to))
                        setStatusFriday(element.status)
                    } else if(element.day === 'saturday'){
                        setStartDateSaturday(new Date(element.from))
                        setEndDateSaturday(new Date(element.to))
                        setStatusSaturday(element.status)
                    }
                });
            }
            setLoading(false)
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })

    },[])

    const List = () => {
        return(
            data.map((element, index)=> (
                <div key={index} className="grid grid-cols-5 capitalize gap-5">
                    <div>{element['id']}</div>
                    <div>{element['day']}</div>
                    <div className='border-gray-500 border align-middle place-content-center p-2'>
                        <DatePicker
                            selected={element['from']}
                            onChange={(date) => element['setStart'](date) }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        />
                    </div>
                    <div className='border-gray-500 border align-middle place-content-center p-2 flex justify-center'>
                        <DatePicker
                            selected={element['to']}
                            onChange={(date) => element['setEnd'](date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        />
                    </div>
                    <div className="justify-center flex">
                        <div className="form-control">
                            <label className="cursor-pointer label">
                                <input type="checkbox" checked={element['status']} className="toggle" onChange={() => ( element['setStatus'](!element['status']) )} />
                            </label>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    const handleSave = () => {
        //save data to db
        updateBranchTimeSlot(data).then(res => {
            successToast('Updated Sucessfully')
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    return (
        <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2">Opening Time</li>
                    </ol>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <div className="justify-between grid grid-flow-col">
                        <div className="text-3xl font-semibold py-8">Branch Opening Time</div>
                        <div>
                            <button className="btn btn-error btn-block btn-md px-8" onClick={() => (handleSave())}>Save</button>
                        </div>
                    </div>
                    {loading?<>Loading....</>:
                        <div className="grid grid-flow-row mt-5 gap-7">
                            <div className="grid grid-flow-col justify-self-auto text-gray-400 capitalize">
                                <div></div>
                                <div className="text-center">day</div>
                                <div className="text-center">from</div>
                                <div className="text-center">to</div>
                                <div className="text-center">Status</div>
                            </div>
                            <List />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
