import React, {useState} from 'react';
import Logo from '../../assets/images/logo/Logo.png';
import {loginUser} from '../../redux/';
import {connect} from 'react-redux';
import Loader from '../../components/Loader';

function Login({userData, loginUser}) {
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });

  const { email, password} = inputs;
  
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleSubmitLogin(e) {
    e.preventDefault();
    if (email && password) {
        loginUser(inputs)
    }
  }

  return (
    <div className="flex items-center min-h-screen bg-white dark:bg-gray-900">
      <div className="container mx-auto">
        <div className="max-w-md mx-auto my-10">
            <div className="flex justify-center">
              <img src={Logo} alt="aloo tama" className={"h-40 py-5"} />
            </div>
            <div className="text-center">
              <p className="text-gray-500 dark:text-gray-400">Sign in to access your account</p>
            </div>
            <div className="m-7">
              <form onSubmit={handleSubmitLogin}>
                <div className="mb-6">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                    <input type="email" name="email" id="email" placeholder="you@company.com" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" required onChange={handleChange} autoFocus autoComplete="on" />
                </div>
                <div className="mb-6">
                    <div className="flex justify-between mb-2">
                        <label className="text-sm text-gray-600 dark:text-gray-400">Password</label>
                    </div>
                    <input type="password" name="password" id="password" placeholder="Your Password" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" required onChange={handleChange} autoComplete="on" />
                </div>
                <div className="mb-6">
                  <button type="submit" className="w-full px-3 py-4 text-white bg-sec rounded-md ocus:bg-sec focus:outline-none cursor-pointer">
                      Login
                  </button>
                </div>
              </form>
            </div>
            <div className="flex justify-center">
              {userData.loading?<Loader /> :null}
            </div>
            <div className="text-center">
              <p className="text-gray-500 dark:text-gray-400">{userData.error}</p>
            </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      userData: state.loginReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      loginUser: (data) => dispatch(loginUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)