import React, { Component } from 'react';
import { errorToast, successToast } from '../../../../../components/Alerts';
import {history} from '../../../../../helper/history';
import { storeFood } from '../../../../../services/menuService';
import { getAllVat, getAllTags } from '../../../../../services';
import Select from 'react-select';

class AddFood extends Component {
    
    constructor(props){
        
        super(props);
        
        this.state = {
            name: '',
            description: '',
            vat_id: '',
            price: '',
            tags: [],
            vats: [],
            suggestions: [],
        }

        this.handleName = this.handleName.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handlePrice = this.handlePrice.bind(this);
        this.handleVatID = this.handleVatID.bind(this);
        this.handleTags = this.handleTags.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); 
    }

    componentDidMount(){
        getAllVat().then(res => {
            this.setState({vats: res.data.message})
        }).catch(err => {
            console.log(err)
        })

        getAllTags().then(res => {      
            res.data.message.forEach(element => {
              this.setState({suggestions: [...this.state.suggestions, {id: element.id, value: element.icon, label: element.name}] })
            });
        })
    }

    handleName(e){
        this.setState({
            name: e.target.value
        })
    }

    handleDescription(e){
        this.setState({
            description: e.target.value
        })
    }

    handlePrice(e){
        this.setState({
            price: e.target.value
        })
    }

    handleVatID(e){
        this.setState({
            vat_id: e.target.value
        })
    }

    handleTags(e){
        this.setState({
            tags: e
        })
    }

    handleSubmit(e){
        e.preventDefault();
        storeFood(this.state.name, 0, this.props.match.params.subId, this.state.description, this.state.price, JSON.stringify(this.state.tags), this.state.vat_id).then(res => {
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/menu')
                successToast('Food added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2"><a href="/menu">Menu</a></li>
                            <li>/</li>
                            <li className="px-2">Add Food</li>
                        </ol>
                    </div>
                </div>
                <div className="grid grid-flow-col mt-5">
                    <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                        <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                            <div className="grid gap-y-5">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Name<span className="px-2 text-sec">*</span></span>
                                    </label> 
                                    <input type="text" placeholder="Enter Food Name" className="input border-gray-100" name="name" required autoFocus onChange={this.handleName} />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Description</span>
                                    </label>
                                    <textarea className="textarea h-24 textarea-bordered" placeholder="Enter Desciption about the food" name="description" onChange={this.handleDescription}></textarea>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Price<span className="px-2 text-sec">*</span></span>
                                    </label> 
                                    <input type="number" step="0.01" min="0" max="10000" placeholder="Enter Food Price" className="input border-gray-100" name="price" required autoFocus onChange={this.handlePrice} />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Tags</span>
                                    </label> 
                                    <Select
                                        isMulti 
                                        closeMenuOnSelect={false}
                                        options={this.state.suggestions}
                                        onChange={this.handleTags}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">VAT Option<span className="px-2 text-sec">*</span></span>
                                    </label> 
                                    <select className="select select-bordered w-full max-w-xs" onChange={this.handleVatID}>
                                        <option value="">Choose VAT type</option>
                                        {
                                            Object.entries(this.state.vats).map( ( [key] ) => (
                                                <option value={this.state.vats[key]['id']}>{this.state.vats[key]['type']} ({this.state.vats[key]['value']} %)</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="form-control py-5">
                                    <button type="submit" className="btn bg-sec hover:bg-sec">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default AddFood;