import React, { Component } from 'react';
import TillReport from '../../../components/table/TillTable';
import { getAllTills } from '../../../services';

class Till extends Component {
    
    col = [
        {
            field: "ID",
            use: "ID"
        },
        {
            field: "by",
            use: "By"
        },
        {
            field: "amount",
            use: "Amount"
        },
        {
            field: "comment",
            use: "Comment"
        },
        {
            field: "type",
            use: "Type"
        },
    ];

    state = {
        row: [],
    }

    componentDidMount(){
        getAllTills().then(res => {
            let i = 1
            res.data.message.forEach(element => {
                this.setState({row: [...this.state.row,{ID: i, by: element.fname+' '+element.lname, amount: element.amount ,comment:element.comment, type: element.type}]})
                i++
            });
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2">Till Report</li>
                        </ol>
                    </div>
                </div>
                <TillReport table_header={'Till Report'} col={this.col} row={this.state.row} />
            </>
        );
    }
}

export default Till;