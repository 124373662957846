import React, { Component } from 'react';
import { errorToast, successToast } from '../../../../../components/Alerts';
import {history} from '../../../../../helper/history';
import { storeMainMenu } from '../../../../../services/menuService';

class AddMain extends Component {
    
    constructor(props){
        
        super(props);
        
        this.state = {
            name: '',
        }

        this.handleName = this.handleName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleName(e){
        this.setState({
            name: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        storeMainMenu(this.state.name,0).then(res => {
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/menu')
                successToast('New Main Menu added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
            <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2"><a href="/menu">Menu</a></li>
                        <li>/</li>
                        <li className="px-2">Add Main Category</li>
                    </ol>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                        <div className="grid gap-y-5">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Main Menu Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter Main menu Name" className="input border-gray-100" name="name" required autoFocus onChange={this.handleName} />
                            </div>
                            <div className="form-control py-5">
                                <button type="submit" className="btn bg-sec hover:bg-sec">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </>
        );
    }
}

export default AddMain;