import React, { Component } from 'react';
import { errorToast, successToast } from '../../../../../components/Alerts';
import {history} from '../../../../../helper/history';
import { getMenuById, updateSubMenu, handleDeleteMenu } from '../../../../../services/';
import BIN from '../../../../../assets/images/icons/bin.png';

class EditSub extends Component {
    constructor(props){
        
        super(props);
        
        this.state = {
            name: '',
        }

        this.handleName = this.handleName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount(){
        getMenuById(this.props.match.params.subId).then(res => {
            let data = res.data.message
            this.setState({ name: data.name})
        }).catch(err => {
            console.log(err)
        })
    }

    handleName(e){
        this.setState({
            name: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        updateSubMenu(this.state.name, 1, 1, this.props.match.params.subId).then(res => {
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/menu')
                successToast('New Sub Menu added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    handleDelete(id){
        handleDeleteMenu(id).then(res => {
            history.push('/menu')
            successToast('Food Deleted Sucessfully')
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
            <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2"><a href="/menu">Menu</a></li>
                        <li>/</li>
                        <li className="px-2">Edit Sub Category</li>
                    </ol>
                </div>
                <div className="flex justify-end">
                    <span className="col-span-2 pr-5">
                        <a href="#delete" className="hover:text-main tooltip tooltip-left" data-tip={"Delete "+this.state.name}>
                            <img src={BIN} className="h-6 w-6" alt="img" />
                        </a>
                    </span>
                    <div id="delete" className="modal">
                        <div className="modal-box">
                            <p className="font-bold text-center">Are you sure you want to delete?</p> 
                            <div className="modal-action mx-auto justify-center">
                                <button className="btn btn-primary" onClick={()=>{this.handleDelete(this.props.match.params.subId)} }>Accept</button> 
                                <button className="btn">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                        <div className="grid gap-y-5">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Sub Category Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter Sub Category Name" className="input border-gray-100" name="name" required autoFocus onChange={this.handleName} value={this.state.name} />
                            </div>
                            <div className="form-control py-5">
                                <button type="submit" className="btn bg-sec hover:bg-sec">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </>
        );
    }
}

export default EditSub;