import {userType} from '../types/userType';
import {login} from '../../services';

export const loginRequest = () => {
    return {
        type: userType.LOGIN_REQUEST,
    }
}

export const loginSuccess = (data) => {
    return {
        type    : userType.LOGIN_SUCCESS,
        payload : data
    }
}

export const loginFailure = (error) => {
    return {
        type    : userType.LOGIN_FAILURE,
        payload : error
    }
}

export const loginUser = (data) => {
    return (dispatch) => {
        dispatch(loginRequest)
        login(data, dispatch)
    }
}