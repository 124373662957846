import axios from 'axios';
import env from '../helper/env';
import {authHeader} from '../helper/authHeader';

export function getAllTags(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/tags',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader()
    })
}

export function storeTag(name, icon){
    let data = {name: name, icon: icon}
    
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/tags',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function deleteTag(id){
    return axios({
        method: 'DELETE',
        baseURL: env.API_URL+'admin/tags/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function getTagDetails(id){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/tags/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateTag(id, name, icon){
    let data = {name: name, icon: icon}
    
    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/tags/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}