import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const CustomRoute = (props) => {
    let path = props.path;
    React.useEffect(() => {
      window.scrollTo(0, 0);
      document.title = props.page_title + ' | Aloo Tama'
    }, [path,props]);
    return <Route {...props} />;
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <CustomRoute {...rest} render={props => (
        localStorage.getItem('token')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
)