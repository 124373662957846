export function authHeader() {
    let token = JSON.parse(localStorage.getItem('token'));

    if (token) {
        return { 'Authorization': `Bearer ` + token,'Accept': 'application/json', 'type': 'admin', 'key': process.env.REACT_APP_API_KEY };
    } else {
        return {};
    }
}

export function imageAuthHeader(){
     // return authorization header with jwt token
     let token = JSON.parse(localStorage.getItem('token'));
 
     if (token) {
         return { 'Authorization': `Bearer ` + token,'Accept': 'application/json', 'Content-Type': 'multipart/form-data'};
     } else {
         return {};
     }
}