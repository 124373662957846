import React, { Component } from 'react';
import Select from 'react-select';
import { errorToast, successToast } from '../../../../../components/Alerts';
import {history} from '../../../../../helper/history';
import { getAllVat, getAllTags, getMenuById, updateFood, handleDeleteMenu } from '../../../../../services';
import BIN from '../../../../../assets/images/icons/bin.png';

class EditFood extends Component {
    constructor(props){
        
        super(props);
        
        this.state = {
            name: '',
            description: '',
            vat_id: '',
            price: '',
            tags: [],
            vats: [],
            suggestions: [],
        }

        this.handleName = this.handleName.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handlePrice = this.handlePrice.bind(this);
        this.handleVatID = this.handleVatID.bind(this);
        this.handleTags = this.handleTags.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount(){
        getAllVat().then(res => {
            this.setState({vats: res.data.message})
        }).catch(err => {
            console.log(err)
        })

        getAllTags().then(res => {    
            res.data.message.forEach(element => {
              this.setState({suggestions: [...this.state.suggestions, {id: element.id, value: element.icon, label: element.name}] })
            });
        }).catch(err => {
            console.log(err)
        })

        getMenuById(this.props.match.params.foodId).then(res => {
            let data = res.data.message
            this.setState({ name: data.name, description: data.description, vat_id: data.vat_id, price: data.price, tags: JSON.parse(data.tags), })
        }).catch(err => {
            console.log(err)
        })
    }

    handleName(e){
        this.setState({
            name: e.target.value
        })
    }

    handleDescription(e){
        this.setState({
            description: e.target.value
        })
    }

    handlePrice(e){
        this.setState({
            price: e.target.value
        })
    }

    handleVatID(e){
        this.setState({
            vat_id: e.target.value
        })
    }

    handleTags(e){
        console.log(e)
        this.setState({
            tags: e
        })
    }

    handleSubmit(e){
        e.preventDefault();
        updateFood(this.state.name, 0, this.props.match.params.subId, this.state.description, this.state.price, JSON.stringify(this.state.tags), this.state.vat_id, this.props.match.params.foodId).then(res => {
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/menu')
                successToast('Food added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    handleDelete(id){
        handleDeleteMenu(id).then(res => {
            history.push('/menu')
            successToast('Food Deleted Sucessfully')
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2"><a href="/menu">Menu</a></li>
                            <li>/</li>
                            <li className="px-2">Update</li>
                        </ol>
                    </div>
                    <div className="flex justify-end">
                        <span className="col-span-2 pr-5">
                            <a href="#delete" className="hover:text-main tooltip tooltip-left" data-tip={"Delete "+this.state.name}>
                                <img src={BIN} className="h-6 w-6" alt='img' />
                            </a>
                        </span>
                        <div id="delete" className="modal">
                            <div className="modal-box">
                                <p className="font-bold text-center">Are you sure you want to delete?</p> 
                                <div className="modal-action mx-auto justify-center">
                                    <button className="btn btn-primary" onClick={()=>{this.handleDelete(this.props.match.params.foodId)} }>Accept</button> 
                                    <button className="btn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-flow-col mt-5">
                    <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                        <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                            <div className="grid gap-y-5">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Name<span className="px-2 text-sec">*</span></span>
                                    </label> 
                                    <input type="text" placeholder="Enter Food Name" className="input border-gray-100" name="name" required autoFocus onChange={this.handleName} value={this.state.name} />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Description</span>
                                    </label>
                                    <textarea className="textarea h-24 textarea-bordered" placeholder="Enter Desciption about the food" name="description" onChange={this.handleDescription} defaultValue={this.state.description} />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Price<span className="px-2 text-sec">*</span></span>
                                    </label> 
                                    <input type="number" step="0.01" min="0" max="10000" placeholder="Enter Food Price" className="input border-gray-100" name="price" required autoFocus onChange={this.handlePrice} value={this.state.price} />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Tags</span>
                                    </label> 
                                    <Select
                                        isMulti 
                                        closeMenuOnSelect={false}
                                        options={this.state.suggestions}
                                        onChange={this.handleTags}
                                        value={this.state.tags}
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">VAT Option<span className="px-2 text-sec">*</span></span>
                                    </label> 
                                    <select className="select select-bordered w-full max-w-xs" onChange={this.handleVatID}>
                                        <option value="">Choose VAT type</option>
                                        {
                                            Object.entries(this.state.vats).map( ( [key] ) => (
                                                <option value={this.state.vats[key]['id']} selected={this.state.vats[key]['id'] === this.state.vat_id?true:false}>{this.state.vats[key]['type']} ({this.state.vats[key]['value']} %)</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="form-control py-5">
                                    <button type="submit" className="btn bg-sec hover:bg-sec">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default EditFood;