import React, { Component } from 'react';
import MainButton from '../../../components/button/MainButton';
import VatTable from '../../../components/table/VatTable';
import { getAllVat } from '../../../services';

class Vat extends Component {
    col = [
        {
            field: "ID",
            use: "ID"
        },
        {
            field: "type",
            use: "Type"
        },
        {
            field: "value",
            use: "Percentage (%)"
        },
        {
            field: "action",
            use: " "
        },
    ];

    state = {
        row: [],
    }

    componentDidMount(){
        getAllVat().then(res => {
            let i = 1
            res.data.message.forEach(element => {
                this.setState({row: [...this.state.row,{ID: i, type: element.type, value: element.value, vat_id: element.id}]})
                i++
            });
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2">VAT</li>
                        </ol>
                    </div>
                    <div className="flex justify-end">
                        <MainButton link="/vat/add" text="Add New VAT" />
                    </div>
                </div>
                <VatTable table_header={'VAT\'s'} col={this.col} row={this.state.row} />
            </>
        );
    }
}

export default Vat;