import React, { Component } from 'react';
import MainButton from '../../../components/button/MainButton';
import UserTable from '../../../components/table/UserTable';
import { getAllBranchUsers } from '../../../services';
class Users extends Component {

    col = [
        {
            field: "ID",
            use: "ID"
        },
        {
            field: "name",
            use: "Name"
        },
        {
            field: "branch_location",
            use: "Branch"
        },
        {
            field: "action",
            use: " "
        },
    ];

    state = {
        row: [],
    }

    componentDidMount(){
        getAllBranchUsers().then(res => {
            let i = 1
            res.data.message.forEach(element => {
                this.setState({row: [...this.state.row,{ID: i, name: element.fname+' '+element.lname,branch_location: element.branch_name ,user_id:element.id}]})
                i++
            });
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2">Users</li>
                        </ol>
                    </div>
                    <div className="flex justify-end">
                        <MainButton link="/branch-user/add" text="Add New User" />
                    </div>
                </div>
                <UserTable table_header={'Users'} col={this.col} row={this.state.row} />
            </>
        );
    }
}

export default Users;