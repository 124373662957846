import React, { Component } from 'react';
import MenuList from '../../../components/MenuList';
import { getAllMenu } from '../../../services';
class Menu extends Component {
    
    constructor(props){
        
        super(props);
        
        this.state = {
           menus: [],
        }
    }

    componentDidMount(){
        getAllMenu().then(res => {
            let data = res.data.message
            let final = []

            //sort menu
            data.forEach(element => {
                let main = []
                
                if(element.parent_id === 0){
                    let sub = []
                    data.forEach(e => {
                        if(e.parent_id !== 0 && e.parent_id === element.id){
                            let food = []
                            data.forEach(el => {
                                if(el.parent_id !==0 && el.parent_id === e.id){
                                    food.push(el)
                                }
                            })
                            sub.push({'id': e.id,'name':e.name, 'data': food})
                        }
                    })
                    main.push({'id': element.id, 'name': element.name, 'sub_category': sub})
                    final.push(main)
                }
            });
            
            this.setState({menus: final})
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2">Menus</li>
                        </ol>
                    </div>
                    <div className="flex justify-end">
                        <div className="dropdown dropdown-hover dropdown-left">
                            <div className="m-1 btn btn-sm"><a href="/menu/category/main" className="btn-sm">Add Main</a></div> 
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        {Object.entries(this.state.menus).map(
                            ( [key, value] ) => (
                                <MenuList id={value[0].id} name={value[0].name} sub={value[0].sub_category} />
                            )
                        )}
                    </div>
                </div>
          </>

        );
    }
}

export default Menu;