const Loader = () => {
    let circleCommonClasses = 'h-3 w-3 bg-main rounded-full';
    return (
        <div className='flex'>
            <div className={`${circleCommonClasses} mr-2 animate-bounce`}></div>
            <div
                className={`${circleCommonClasses} mr-2 animate-bounce200`}
            ></div>
            <div className={`${circleCommonClasses} animate-bounce300`}></div>
        </div>
    );
};

export default Loader;