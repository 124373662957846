import React ,{useEffect}from 'react';

function Dashboard() {
  useEffect(() => {
      document.title = 'Dashboard | Aloo Tama'
  }, []);

  return (
    <>
    </>
  );
}

export default Dashboard;