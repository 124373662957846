import axios from 'axios';
import env from '../helper/env';
import {authHeader} from '../helper/authHeader';

export function getAllLocation(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/branches',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader()
    })
}

export function storeNewLocation(branch_name, phone_num, email_ad, address_h){
    // check if value is there or not
    let data = {name: branch_name, }
    if(phone_num !== ''){
        Object.assign(data, {phone: phone_num})
    }

    if(email_ad !== ''){
        Object.assign(data, {email: email_ad})
    }

    if(address_h !== ''){
        Object.assign(data, {address: address_h})
    }
    
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/branches',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function deleteBranch(id){
    return axios({
        method: 'DELETE',
        baseURL: env.API_URL+'admin/branches/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateBranch(id, branch_name, phone_num, email_ad, address_h){
    
    let data = {name: branch_name, }
    if(phone_num !== ''){
        Object.assign(data, {phone: phone_num})
    }

    if(email_ad !== ''){
        Object.assign(data, {email: email_ad})
    }

    if(address_h !== ''){
        Object.assign(data, {address: address_h})
    }

    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/branches/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function getBranch(id){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/branches/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function getAllBranchUsers(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/branch/users',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function storeNewBranchUser(fname, lname, phone, email, address, password, branch_id){
    let data = {fname: fname, lname: lname, email: email, phone: phone, address: address, password: password, branch_id: branch_id }
    
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/branch/users',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function deleteBranchUser(id){
    return axios({
        method: 'DELETE',
        baseURL: env.API_URL+'admin/branch/users/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function getBranchUserDetails(id){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/branch/users/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateBranchUser(id,fname, lname, phone, email, address, password, branch_id){
    let data = {fname: fname, lname: lname, email: email, phone: phone, address: address, password: password, branch_id: branch_id, user_id: id }
    
    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/branch/users/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function getBranchTimeSlot(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/branch/time',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateBranchTimeSlot(slots){
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/branch/time',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: {'slots':slots}
    })
}

export function getBranchSetting(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/branch/setting',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateBranchSetting(collection, delivery, cash, card, minOrder, collectionTime, deliveryTime){
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/branch/setting',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: {'collection':collection, 'cash': cash, 'card': card, 'delivery': delivery, 'minimu_order': minOrder, 'collection_time': collectionTime, 'delivery_time': deliveryTime}
    })
}