import React, { Component } from 'react';
import MainButton from '../../../components/button/MainButton';
import BranchTable from '../../../components/table/BranchTable';
import {getAllLocation} from '../../../services';
class Location extends Component {
    
    col = [
        {
            field: "ID",
            use: "ID"
        },
        {
            field: "name",
            use: "Name"
        },
        {
            field: "phone",
            use: "Phone"
        },
        {
            field: "address",
            use: "Address"
        },
        {
            field: "action",
            use: " "
        },
    ];

    state = {
        row: [],
    }

    componentDidMount(){
        getAllLocation().then(res => {
            let i = 1
            res.data.message.forEach(element => {
                this.setState({row: [...this.state.row,{ID: i, name: element.name, phone: element.phone, address:element.address, branch_id:element.id}]})
                i++
            });
        })
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2">Location</li>
                        </ol>
                    </div>
                    <div className="flex justify-end">
                        <MainButton link="/location/add" text="Add New Location" />
                    </div>
                </div>
                <BranchTable table_header={'All Locations'} col={this.col} row={this.state.row} />
            </>
        );
    }
}

export default Location;