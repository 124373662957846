import axios from 'axios';
import env from '../helper/env';
import {authHeader} from '../helper/authHeader';

export function getAllMenu(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/menus',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader()
    })
}

export function storeMainMenu(name, is_submenu){
    let data = {name: name, is_subMenu:is_submenu}

    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/menus',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function updateMainMenu(name, is_submenu, parent_id, id){
    let data = {name: name, is_subMenu: is_submenu, parent_id: parent_id}

    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/menus/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function getAllMainMenus(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/menu/main',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function storeSubMenu(name, is_submenu, parent_id){
    let data = {name: name, is_subMenu: is_submenu, parent_id: parent_id}

    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/menus',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function updateSubMenu(name, is_submenu, parent_id, id){
    let data = {name: name, is_subMenu: is_submenu, parent_id: parent_id}

    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/menus/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function storeFood(name, is_submenu, parent_id, description, price, tags, vat_id){
    let data = {name: name, is_subMenu: is_submenu, parent_id: parent_id, vat_id: vat_id, description: description, price: price, tags: tags}

    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/menus',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function getMenuById(id){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/menus/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateFood(name, is_submenu, parent_id, description, price, tags, vat_id, id){
    let data = {name: name, is_subMenu: is_submenu, parent_id: parent_id, vat_id: vat_id, description: description, price: price, tags: tags}

    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/menus/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function handleDeleteMenu(id){
    return axios({
        method: 'DELETE',
        baseURL: env.API_URL+'admin/menus/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}