import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { errorToast, successToast } from '../../components/Alerts';
import { updatePassword, updatePin } from '../../services';

class Setting extends Component {
    constructor(props){
        
        super(props);
        
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            newPin: '',
            confirmPin: '',
        }

        this.handleCurrentPassword = this.handleCurrentPassword.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.handleNewPin = this.handleNewPin.bind(this);
        this.handleConfirmPin = this.handleConfirmPin.bind(this);
        this.handlePinSubmit = this.handlePinSubmit.bind(this);
    }

    handleCurrentPassword(e){
        this.setState({
            currentPassword: e.target.value
        })
    }

    handleNewPassword(e){
        this.setState({
            newPassword: e.target.value
        })
    }

    handleConfirmPassword(e){
        this.setState({
            confirmNewPassword: e.target.value
        })
    }

    handleNewPin(e){
        this.setState({
            newPin: e.target.value
        })
    }

    handleConfirmPin(e){
        this.setState({
            confirmPin: e.target.value
        })
    }

    handlePasswordSubmit(e){
        e.preventDefault();
        if(this.state.newPassword === this.state.confirmNewPassword){
            updatePassword(this.state.currentPassword, this.state.newPassword, this.state.confirmNewPassword).then(res => {
                if(res.data.error){
                    let errors = res.data.error
                    Object.keys(errors).forEach((element,key) => {
                        errorToast(errors[key])
                    });
                }else {
                    window.location.reload();
                    successToast('Password Updated Sucessfully')
                }
            })
        }else{
            errorToast('Password Conformation Failed, please try again')
        }
    }

    handlePinSubmit(e){
        e.preventDefault();
        if(this.state.newPin === this.state.confirmPin){
            updatePin(this.state.currentPassword, this.state.newPin, this.state.confirmPin).then(res => {
                if(res.data.error){
                    let errors = res.data.error
                    errorToast(errors)
                }else {
                    window.location.reload();
                    successToast('Pin Updated Sucessfully')
                }
            })
        }else{
            errorToast('Password Conformation Failed, please try again')
        }
    }

    render() {
        return (
            <>
                <div className="grid grid-flow-col">
                    <div className="flex justify-start">
                        <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                            <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                            <li>/</li>
                            <li className="px-2">Setting</li>
                        </ol>
                    </div>
                </div>
                <div className="grid bg-white py-5 m-5 rounded-md">
                    <Tabs className="tabs grid p-8">
                        <TabList>
                            <Tab className="tab-bordered tab">Change Password</Tab>
                            <Tab className="tab-bordered tab">Chaneg Pin</Tab>
                        </TabList>
                        <TabPanel className="grid">
                            <form className="container mx-auto md:w-3/4" onSubmit={this.handlePasswordSubmit}>
                                <div className="grid gap-y-5">
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Current Password</span>
                                        </label> 
                                        <input type="password" placeholder="Enter your current password"  className="input border-gray-100" name="current_password" onChange={this.handleCurrentPassword} />
                                    </div>
                                    <hr />
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">New Password</span>
                                        </label> 
                                        <input type="password" placeholder="Enter your new password"  className="input border-gray-100" name="new_password" onChange={this.handleNewPassword} />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Confirm Password</span>
                                        </label> 
                                        <input type="password" placeholder="Re-Enter your new password"  className="input border-gray-100" name="confirm_password" onChange={this.handleConfirmPassword} />
                                    </div>
                                    <div className="form-control py-5">
                                        <button type="submit" className="btn bg-sec hover:bg-sec">Update Password</button>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel className="grid">
                            <form className="container mx-auto md:w-3/4" onSubmit={this.handlePinSubmit}>
                                <div className="grid gap-y-5">
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Current Password</span>
                                        </label> 
                                        <input type="password" placeholder="Enter your current password"  className="input border-gray-100" name="current_password" onChange={this.handleCurrentPassword} />
                                    </div>
                                    <hr />
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">New Pin</span>
                                        </label> 
                                        <input type="password" placeholder="Enter your new pin"  className="input border-gray-100" name="new_pin" onChange={this.handleNewPin} />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Confirm Pin</span>
                                        </label> 
                                        <input type="password" placeholder="Confirm your new password"  className="input border-gray-100" name="pin_confirm" onChange={this.handleConfirmPin} />
                                    </div>
                                    <div className="form-control py-5">
                                        <button type="submit" className="btn bg-sec hover:bg-sec">Update Pin</button>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </div>
            </>
        );
    }
}

export default Setting;