import axios from 'axios';
import env from '../helper/env';
import { history } from '../helper/history';
import {loginSuccess, loginFailure} from '../redux/actions';
import {authHeader} from '../helper/authHeader';

//getting user id
function getUserId(){
    return JSON.parse(localStorage.getItem('user')).id
}

function storeToken(token){
    localStorage.setItem('token', token);
}

export function login(data, dispatch){
    axios({
        method: 'POST',
        headers: {'type': 'admin', 'key': process.env.REACT_APP_API_KEY},
        baseURL: env.API_URL+'login',
        data: {email: data.email, password: data.password},
    }).then(res => {
        if(!res.data.error){
            storeToken(JSON.stringify(res.data.token))
            dispatch(loginSuccess(res.data.user));
            window.location.reload();
            history.push('/');
        }else{
            dispatch(loginFailure("Invalid Email OR Password, Please try again"))
        }
    }).catch(err => {
        const error = err.response.data.error
        dispatch(loginFailure(error))
    })
}

export function update(data){
    return axios({
        method: 'POST',
        headers: authHeader(),
        baseURL: env.API_URL+'user/'+getUserId()+'/profile',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        data: data,
    })
}

export function logout(){
    return axios({
        method: 'GET',
        headers: authHeader(),
        baseURL: env.API_URL+'admin/logout',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
    })
}

export function verifyPin(value){
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/pin/verify',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: {pin: value}
    })
}

export function updatePassword(old_pass, new_pass, confirm_pass){
    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/password',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: {old_password: old_pass, password: new_pass, password_confirmation: confirm_pass}
    })
}

export function updatePin(pass, pin, confirm_pin){
    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/pin',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: {password: pass, pin: pin, pin_confirmation: confirm_pin}
    })
}