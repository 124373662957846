import React, { Component } from 'react';
import { errorToast, successToast } from '../../../components/Alerts';
import {history} from '../../../helper/history';
import {updateBranchUser, getAllLocation, getBranchUserDetails} from '../../../services'
class EditUser extends Component {

    constructor(props){
        
        super(props);
        
        this.state = {
            fname: '',
            lname: '',
            email: '',
            phone: '',
            address: '',
            password: '',
            branch_id: '',
            branches: [],
        }

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeBranchID = this.handleChangeBranchID.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        let url = this.props.match.params.slug
        
        getAllLocation().then(res => {
            let data = res.data.message
            data.forEach(e => {
                this.setState({
                    branches: [
                        ...this.state.branches, {'id': e.id, 'name': e.name}]
                })
            })
        })

        getBranchUserDetails(url).then(res => {
            let data = res.data.message[0]
            this.setState({fname: data.fname, lname:data.lname, branch_id: data.branch_id, phone: data.phone, email: data.email, address: data.address})
        })
    }

    handleChangeFirstName(e){
        this.setState({
            fname: e.target.value
        })
    }

    handleChangeLastName(e){
        this.setState({
            lname: e.target.value
        })
    }

    handleChangeEmail(e){
        this.setState({
            email: e.target.value
        })
    }

    handleChangePhone(e){
        this.setState({
            phone: e.target.value
        })
    }

    handleChangeAddress(e){
        this.setState({
            address: e.target.value
        })
    }

    handleChangePassword(e){
        this.setState({
            password: e.target.value
        })
    }

    handleChangeBranchID(e){
        this.setState({
            branch_id: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        updateBranchUser(this.props.match.params.slug, this.state.fname, this.state.lname, this.state.phone, this.state.email, this.state.address, this.state.password, this.state.branch_id).then(res=>{
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/branch-user')
                successToast('New Branch User Added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
        <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2"><a href="/branch-user">Branch Users</a></li>
                        <li>/</li>
                        <li className="px-2">Add</li>
                    </ol>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                        <div className="grid gap-y-5">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Select Branch<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <select className="select select-bordered w-full max-w-xs" required name="branch_id" onChange={this.handleChangeBranchID}>
                                    <option value="">Choose your Branch Location</option>
                                    {
                                        Object.entries(this.state.branches).map( ( [key, value] ) => (
                                            <option value={this.state.branches[key]['id']} selected={this.state.branches[key]['id'] === this.state.branch_id?true:false}>{this.state.branches[key]['name']}</option>
                                        ))
                                    }

                                </select>
                            </div>
                            <hr />
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">First Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter your First Name" className="input border-gray-100" name="fname" value={this.state.fname} required autoFocus onChange={this.handleChangeFirstName} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Last Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter your Last Name" className="input border-gray-100" name="lname" value={this.state.lname} required autoFocus onChange={this.handleChangeLastName} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Phone Number</span>
                                </label> 
                                <input type="phone" placeholder="Enter Branch contact number"  className="input border-gray-100" name="phone" value={this.state.phone} onChange={this.handleChangePhone} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Address</span>
                                </label> 
                                <input type="text" placeholder="Enter your Branch Address"  className="input border-gray-100" name="address" value={this.state.address} onChange={this.handleChangeAddress} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label> 
                                <input type="email" placeholder="Enter your Branch Name"  className="input border-gray-100" name="email" value={this.state.email} onChange={this.handleChangeEmail} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Password</span>
                                </label> 
                                <input type="password" placeholder="Enter your Branch Name"  className="input border-gray-100" name="password" onChange={this.handleChangePassword} />
                            </div>
                            <div className="form-control py-5">
                                <button type="submit" className="btn bg-sec hover:bg-sec">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
        );
    }
}

export default EditUser;