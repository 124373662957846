import React, { Component } from 'react';
import SPICY from '../../../assets/images/icons/spicy.png';
import HOT from '../../../assets/images/icons/hot.png';
import { getTagDetails, updateTag } from '../../../services';
import { errorToast, successToast } from '../../../components/Alerts';
import {history} from '../../../helper/history';
import VEG from '../../../assets/images/icons/vegetarian.jpg';
import GLU from '../../../assets/images/icons/gluten_free.png';
class EditTag extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            name: '',
            icon: '',
        }

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeIcon = this.handleChangeIcon.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        let url = this.props.match.params.slug
        
        //get location details
        getTagDetails(url).then(res => {
            let data = res.data.message
            this.setState({name: data.name, icon: data.icon})
        })
    }

    handleChangeName(e){
        this.setState({
            name: e.target.value
        })
    }

    handleChangeIcon(e){
        this.setState({
            icon: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        updateTag(this.props.match.params.slug, this.state.name, this.state.icon).then(res=>{
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/tags')
                successToast('Tag updated Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
            <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2"><a href="/tags">Tags</a></li>
                        <li>/</li>
                        <li className="px-2">Update</li>
                    </ol>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                        <div className="grid gap-y-5">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter Tag Name" className="input border-gray-100" name="type" required autoFocus value={this.state.name} onChange={this.handleChangeName} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Choose Icon for your tag Name *</span>
                                </label> 
                            </div>
                            <div className="">
                            <div className="form-control">
                                    <label className="cursor-pointer label">
                                    <span className="label-text"><div className="grid grid-flow-col items-center">
                                        <div>1. Vegetarian</div>
                                        <div className="pl-5">
                                            <img src={VEG} alt='img' height="30" width="30" /></div>
                                        </div>
                                    </span> 
                                    <input type="radio" name="opt" className="radio" value="vegetarian" required onChange={this.handleChangeIcon} />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="cursor-pointer label">
                                    <span className="label-text"><div className="grid grid-flow-col items-center">
                                        <div>2. Slightly spiced</div>
                                        <div className="pl-5">
                                            <img src={SPICY} alt='img' height="30" width="30" /></div>
                                        </div>
                                    </span> 
                                    <input type="radio" name="opt" className="radio" value="slightly_spiced" required onChange={this.handleChangeIcon} />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="cursor-pointer label">
                                    <span className="label-text"><div className="grid grid-flow-col items-center">
                                        <div>3. Very Hot</div>
                                        <div className="pl-5">
                                            <img src={HOT} alt='img' height="30" width="30" /></div>
                                        </div>
                                    </span> 
                                    <input type="radio" name="opt" className="radio" value="very_hot" required onChange={this.handleChangeIcon} />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="cursor-pointer label">
                                    <span className="label-text"><div className="grid grid-flow-col items-center">
                                        <div>4. Glutan Free</div>
                                        <div className="pl-5">
                                            <img src={GLU} alt='img' height="30" width="30" /></div>
                                        </div>
                                    </span> 
                                    <input type="radio" name="opt" className="radio" value="glutan_free" required onChange={this.handleChangeIcon} />
                                    </label>
                                </div>
                            </div>
                            <div className="form-control py-5">
                                <button type="submit" className="btn bg-sec hover:bg-sec">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
        );
    }
}

export default EditTag;