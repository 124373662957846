import axios from 'axios';
import env from '../helper/env';
import {authHeader} from '../helper/authHeader';

export function getAllVat(){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/vats',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader()
    })
}

export function storeVat(type, value){
    let data = {type: type, value: value}
    
    return axios({
        method: 'POST',
        baseURL: env.API_URL+'admin/vats',
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}

export function deleteVat(id){
    return axios({
        method: 'DELETE',
        baseURL: env.API_URL+'admin/vats/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function getVatDetails(id){
    return axios({
        method: 'GET',
        baseURL: env.API_URL+'admin/vats/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
    })
}

export function updateVat(id,type, value){
    let data = {type: type, value: value}
    
    return axios({
        method: 'PUT',
        baseURL: env.API_URL+'admin/vats/'+id,
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },
        headers: authHeader(),
        data: data
    })
}