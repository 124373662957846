import React, { Component } from 'react';
import { errorToast, successToast } from '../../../components/Alerts';
import { storeVat } from '../../../services';
import {history} from '../../../helper/history';

class AddNewVat extends Component {
    constructor(props){
        
        super(props);
        
        this.state = {
            type: '',
            value: '',
        }

        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeType(e){
        this.setState({
            type: e.target.value
        })
    }

    handleChangeValue(e){
        this.setState({
            value: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        storeVat(this.state.type, this.state.value).then(res=>{
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/vat')
                successToast('New VAT Added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
        <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2"><a href="/vat">VAT</a></li>
                        <li>/</li>
                        <li className="px-2">Add</li>
                    </ol>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                        <div className="grid gap-y-5">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter VAT type Name" className="input border-gray-100" name="type" required autoFocus onChange={this.handleChangeType} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Percentage</span>
                                </label> 
                                <input type="number" min="0" step="0.01" max="100" placeholder="Enter VAT percenatge"  className="input border-gray-100" name="value" onChange={this.handleChangeValue} />
                            </div>
                            <div className="form-control py-5">
                                <button type="submit" className="btn bg-sec hover:bg-sec">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
        );
    }
}

export default AddNewVat;