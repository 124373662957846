import React from 'react';
import Route from './route/route'
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export default class App extends React.Component {
  render() {
    return (
      <>
        <Route />
        <ToastContainer />
      </>
    )
  }
}