import React from 'react';

function MenuList(props) {
    return (
        <ul className="menu border-gray-300 border-2 my-5">
            <li className="bg-gray-300 grid">
                <div className="grid grid-flow-col grid-cols-2">
                    <span className="justify-start col-span-2 p-5 text-xl font-bold">{props.name}</span>
                    <span className="col-span-2 pr-5 py-5">
                        <a href={"/menu/category/main/"+props.id+"/edit"} className="hover:text-main tooltip tooltip-left"data-tip={"Edit "+props.name}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </a>
                    </span>
                    <span className="col-span-2 pr-5 py-5">
                        <a href={"/menu/category/sub/"+props.id} className="hover:text-main tooltip tooltip-left"data-tip={"Add New Sub Menu"}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </a>
                    </span>
                </div>
            </li>  
            {Object.entries(props.sub).map(
                ( [key, value] ) => (
                    <ul className="menu">
                        <li className="bg-gray-200 pl-5">
                            <div className="grid grid-flow-col grid-cols-2">
                                <span className="justify-start col-span-2 p-3 text-lg font-semibold">{value.name}</span>
                                <span className="col-span-2 pr-5 py-3">
                                    <a href={"/menu/category/sub/"+value.id+"/edit"} className="hover:text-main tooltip tooltip-left" data-tip={"Edit "+value.name}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </a>
                                </span>
                                <span className="col-span-2 pr-5 py-3">
                                    <a href={"/menu/category/food/"+value.id} className="hover:text-main tooltip tooltip-left"data-tip={"Add Food"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </a>
                                </span>
                            </div>
                        </li>
                        {
                            Object.entries(value.data).map((val, index) => (
                                <li className="bg-gray-50 pl-10 py-2">
                                    <div className="grid grid-flow-col grid-cols-3">
                                        <span className="justify-start col-span-2">{val[1].name}</span>
                                        <span className="justify-end text-right pr-10 col-span-2">£ {val[1].price}</span>
                                        <span className="col-span-2 pr-5">
                                            <a href={"/menu/category/food/"+val[1].id+"/edit"} className="hover:text-main tooltip tooltip-left" data-tip={"Edit "+val[1].name}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                            </a>
                                        </span>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                )
            )}
        </ul>
    );
}

export default MenuList;