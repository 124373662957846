import React, { Component } from 'react';
import { errorToast, successToast } from '../../../components/Alerts';
import { updateBranch } from '../../../services/branchService';
import {history} from '../../../helper/history';
import {getBranch} from '../../../services';
class EditLocation extends Component {

    constructor(props){
        
        super(props);
        
        this.state = {
            branch_name: '',
            email: '',
            phone: '',
            address: '', 
            id: ''
        }

        this.handleChangeBranchName = this.handleChangeBranchName.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        let url = this.props.match.params.slug
        
        //get location details
        getBranch(url).then(res => {
            let data = res.data.message
            this.setState({id: data.id,branch_name: data.name, email: data.email, phone: data.phone, address: data.address})
        })
    }

    handleChangeBranchName(e){
        this.setState({
            branch_name: e.target.value
        })
    }

    handleChangeEmail(e){
        this.setState({
            email: e.target.value
        })
    }
    handleChangePhone(e){
        this.setState({
            phone: e.target.value
        })
    }
    handleChangeAddress(e){
        this.setState({
            address: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        updateBranch(this.state.id,this.state.branch_name, this.state.phone, this.state.email, this.state.address).then(res=>{
            if(res.data.error){
                let errors = res.data.error
                Object.keys(errors).forEach(element => {
                    let val = errors[element][0]
                    errorToast(val)
                });
            }else {
                history.push('/location')
                successToast('New Location Added Sucessfully')
            }
        }).catch(err => {
            errorToast('Error, Contact admin!')
        })
    }

    render() {
        return (
        <>
            <div className="grid grid-flow-col">
                <div className="flex justify-start">
                    <ol className="list-reset rounded flex bg-grey-light text-grey items-center">
                        <li className="px-2"><a href="/" className="no-underline text-indigo hover:text-sec">Dashboard</a></li>
                        <li>/</li>
                        <li className="px-2"><a href="/location">Location</a></li>
                        <li>/</li>
                        <li className="px-2">Update</li>
                    </ol>
                </div>
            </div>
            <div className="grid grid-flow-col mt-5">
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white">
                    <form className="container mx-auto md:w-3/4" onSubmit={this.handleSubmit}>
                        <div className="grid gap-y-5">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Branch Name<span className="px-2 text-sec">*</span></span>
                                </label> 
                                <input type="text" placeholder="Enter your new Branch Name" className="input border-gray-100" name="branch_name" value={this.state.branch_name} required autoFocus onChange={this.handleChangeBranchName} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Phone Number</span>
                                </label> 
                                <input type="number" placeholder="Enter Branch contact number"  className="input border-gray-100" name="phone" value={this.state.phone} onChange={this.handleChangePhone} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Address</span>
                                </label> 
                                <input type="text" placeholder="Enter your Branch Address"  className="input border-gray-100" name="address" value={this.state.address} onChange={this.handleChangeAddress} />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label> 
                                <input type="text" placeholder="Enter your Branch Name"  className="input border-gray-100" name="email" value={this.state.email} onChange={this.handleChangeEmail} />
                            </div>
                            <div className="form-control py-5">
                                <button type="submit" className="btn bg-sec hover:bg-sec">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
        );
    }
}

export default EditLocation;