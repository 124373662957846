import React from 'react';
import {Router,Route, Switch, Redirect} from "react-router-dom";
import {Provider} from 'react-redux';
import store from '../redux/store';
import { history } from '../helper/history';
import {PrivateRoute} from './PrivateRoute';
import Login from "../pages/login/Login";
import PageNotFound from "../pages/error/404";
import Dashboard from "../pages/dashboard/Dashboard";
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Setting from '../pages/setting/Setting';
import Location from '../pages/branch/location/Location';
import Users from '../pages/branch/users/Users';
import AddNewUser from '../pages/branch/users/AddNewUser';
import AddNewLocation from '../pages/branch/location/AddNewLocation';
import EditLocation from '../pages/branch/location/EditLocation';
import EditUser from '../pages/branch/users/EditUser';
import AddNewVat from '../pages/menu/vat/AddNewVat';
import Vat from '../pages/menu/vat/Vat';
import EditVat from '../pages/menu/vat/EditVat';
import Menu from '../pages/menu/menu/Menu';
import AddMain from '../pages/menu/menu/category/main/AddMain';
import AddSub from '../pages/menu/menu/category/sub/AddSub';
import AddFood from '../pages/menu/menu/category/food/AddFood';
import Tags from '../pages/menu/tags/Tags';
import AddNewTag from '../pages/menu/tags/AddNewTag';
import EditTag from '../pages/menu/tags/EditTag';
import EditFood from '../pages/menu/menu/category/food/EditFood';
import EditSub from '../pages/menu/menu/category/sub/EditSub';
import EditMain from '../pages/menu/menu/category/main/EditMain';
import Till from '../pages/report/till/Till';
import OpeningTime from '../pages/branch/time';
import BranchSetting from '../pages/branch/setting';

const CustomRoute = (props) => {
    let path = props.path;
    React.useEffect(() => {
      window.scrollTo(0, 0);
      document.title = props.page_title + ' | Aloo Tama'
    }, [path,props]);
    return <Route {...props} />;
};

export default class route extends React.Component {
    state = {
        isAuth: false,
        sidebarOpen: false,
        setSidebarOpen: false,
    }

    changeStatus(def){
        this.setState({
            isAuth: def
        })
    }

    componentDidMount(){
        localStorage.token?this.changeStatus(true):this.changeStatus(false)
    }

    authRoutes(){
        return (
            <Provider store={store}>
                <Router history={history}>
                    <div className="flex h-screen overflow-hidden">
                        <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={this.state.setSidebarOpen} />
                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <Navbar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={this.state.setSidebarOpen} />
                            <main>
                                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                    <Switch>
                                        {/* private routes */}
                                        <PrivateRoute exact path="/dashboard" component={Dashboard} page_title="Dashboard" />
                                        <PrivateRoute exact path="/setting" component={Setting} page_title="Setting" />
                                        <PrivateRoute exact path="/location" component={Location} page_title="Locations" />
                                        <PrivateRoute exact path="/location/add" component={AddNewLocation} page_title="Add New Location" />
                                        <PrivateRoute exact path="/location/:slug/edit" component={EditLocation} page_title="Edit Location" />
                                        <PrivateRoute exact path="/branch-user" component={Users} page_title="Branch Users" />
                                        <PrivateRoute exact path="/branch-user/:slug/edit" component={EditUser} page_title="Edit Users" />
                                        <PrivateRoute exact path="/branch-user/add" component={AddNewUser} page_title="Add New Branch User" />
                                        <PrivateRoute exact path="/time" component={OpeningTime} page_title="Branch Opening Time" />
                                        <PrivateRoute exact path="/branch/setting" component={BranchSetting} page_title="Branch Setting" />
                                        <PrivateRoute exact path="/vat" component={Vat} page_title="VAT" />
                                        <PrivateRoute exact path="/vat/add" component={AddNewVat} page_title="Add New VAT" />
                                        <PrivateRoute exact path="/vat/:slug/edit" component={EditVat} page_title="Edit VAT" />
                                        <PrivateRoute exact path="/tags" component={Tags} page_title="Tags" />
                                        <PrivateRoute exact path="/tags/add" component={AddNewTag} page_title="Add New Tag" />
                                        <PrivateRoute exact path="/tags/:slug/edit" component={EditTag} page_title="Edit Tag" />
                                        <PrivateRoute exact path="/menu" component={Menu} page_title="Menu's" />
                                        <PrivateRoute exact path="/menu/category/main" component={AddMain} page_title="Add New Main Category" />
                                        <PrivateRoute exact path="/menu/category/main/:mainId/edit" component={EditMain} page_title="Edit Main Category" />
                                        <PrivateRoute exact path="/menu/category/sub/:mainID" component={AddSub} page_title="Add New Sub Category" />
                                        <PrivateRoute exact path="/menu/category/sub/:subId/edit" component={EditSub} page_title="Edit Sub Category" />
                                        <PrivateRoute exact path="/menu/category/food/:subId" component={AddFood} page_title="Add New Food" />
                                        <PrivateRoute exact path="/menu/category/food/:foodId/edit" component={EditFood} page_title="Edit Food" />
                                        <PrivateRoute exact path="/till" component={Till} page_title="Till Report" />
                                        <Route exact path="/"><Redirect to="/dashboard" /></Route>
                                        <PrivateRoute component={PageNotFound} />
                                        {/* end of private routes */}
                                    </Switch>
                                </div>
                            </main>
                        </div>
                    </div>
                </Router>
            </Provider>
        )
    }

    unAuthRoutes(){
        return(
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        {/* public routes */}
                        <CustomRoute exact path="/" component={Login} page_title="Login" />
                        <CustomRoute exact path="/logout"><Redirect to="/" /></CustomRoute>
                        <CustomRoute component={PageNotFound} />
                        {/* end of public routes */}
                    </Switch>
                </Router>
            </Provider>
        )
    }

    render() {
        return (
            this.state.isAuth===true?this.authRoutes():this.unAuthRoutes()
        )
    }
}